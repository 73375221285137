@use "@angular/material" as mat;
@use "styles/default.scss" as default;
@use "@wellsky/atlas-ui/theming";
@import './styles/apexchart.scss';

html,
body {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: mat.m2-get-color-from-palette(default.$ws-neutral,  200);
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
